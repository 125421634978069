

export const keyData = [
    "title",
    "province",
    "city",
    "county",
    "address",
]


import Storage from "@/util/Storage";
import {Dialog, Toast} from "vant";
import {UpPhotos} from "@/Api/UpPhoto";
import {addSite, updateSite} from "@/Api/SocialCircle";

// 上传 图片
export function upImage(imgArr = []) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    const fromData = new FormData()
    fromData.append("functionName","other")
    imgArr.forEach(item=>fromData.append("files",item.file, item.file.name))
    return (
        new Promise((resolve, reject) => {
            if ( imgArr.length > 2 ){
                Dialog({ title:"温馨提示",message:"上传图片数量大于2张，上传速度较慢，是否继续？" }).then(res=>resolve()).catch(cat=>reject())
            }else{
                resolve()
            }
        }).then(res=>{
            Toast.loading({ duration:0,message:"上传中~~~" })
            return Promise.resolve(UpPhotos({ userId,token },fromData).then(res=>{
                Toast.clear()
                return res.data || []
            }))
        }).catch(cat=>{
            Toast.clear()
        })
    )
}

const placeKey = {
    "title":"请输入标题",
    "province":"请选择地址",
    "city":"请选择地址",
    "county":"请选择地址",
}
// 添加 地址
export function addAddress(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    let keyArr = Object.keys(placeKey),num = 0;
    keyArr.forEach(item=>{
        if ( !body[item] ) {
            Toast(placeKey[item]);
            num ++;
        }
    })
    if ( num ) return Promise.reject()
    Toast.loading({ duration:0,message:"添加中~~~" })
    return Promise.resolve(addSite({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}

// 修改 地址
export function updateAddress(body:any = {},oldData:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    body.id = oldData.id
    let keyArr = Object.keys(body),num = 0;
    keyArr.forEach((item,idx)=>{
        if ( oldData[item] == null ){
            if ( oldData[item] != null && body[item] != oldData[item] ){
                num ++;
            }else{
                if ( oldData[item] == null && body[item].length > 0 ) {
                    num ++;
                }
            }
        }else if ( body[item] != oldData[item] ){
            num ++;
        }
    })
    if ( num < 1){
        Toast("修改信息")
        return Promise.reject()
    }
    Toast.loading({ duration:0,message:"修改中~~~" })
    return Promise.resolve(updateSite({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}
